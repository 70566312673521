import { BE_FE_MAP as QR_BE_FE_MAP } from 'services/QR/constants';

export const ENDPOINTS = {
  template: '/elink-pages',
  getParams: ({ page, perPage, search, sortBy, sortDirection }) =>
    `per_page=${perPage}&page=${page}&search=${search}&sort=${sortBy}&sort_direction=${sortDirection}`,
  createLink: uuid => `/external/elink-pages/${uuid}/links`,
  getExternalPage: slug => `/external/elink-pages/${slug}`,
  getSlug: uuid => `/external/elink-pages/${uuid}/links/slug`,
  validateSlug: uuid => `/external/elink-pages/${uuid}/links/validate-slug`,
  getRequiredAttributes: (elinkPageUuid, linkTemplateUuid) =>
    `external/elink-pages/${elinkPageUuid}/link-templates/${linkTemplateUuid}`,
  convertTypes: `external/convert-file`,
};

export const BE_FE_MAP = {
  getAll: {
    data: {
      name: 'data',
      transform: {
        description: 'description',
        is_default: 'isDefault',
        name: 'title',
        uuid: 'uuid',
        short_url: 'shortUrl',
        'thumbnail_logo.value': 'imageUrl',
        'thumbnail_logo.is_enabled': 'isImageEnabled',
        long_url: 'longUrl',
        slug: 'slug',
      },
    },
    meta: {
      name: 'pagination',
      transform: {
        current_page: 'page',
        per_page: 'perPage',
        last_page: 'totalPages',
      },
    },
  },
  getOne: {
    uuid: 'uuid',
    name: 'name',
    description: 'description',
    slug: 'slug',
    is_default: 'isDefault',
    is_qr_code: 'isQRCodeEnabled',
    is_trusted_destination: 'isTrustedDestinationEnabled',
    is_short_url_editable: 'isShortUrlEditable',
    is_protected: 'isProtected',
    published_at: 'isPublished',
    is_use_existing_link: 'isUseExistingLink',
    redirect_type: {
      name: 'redirectType',
      transform: {
        value: 'value',
        caption: 'label',
      },
    },
    hidden_columns: 'hiddenFields',
    domains: {
      name: 'domains',
      transform: {
        id: 'id',
        is_trusted_destinations: 'isTrustedEnabled',
        name: 'label',
        uuid: 'value',
      },
    },
    link_templates: {
      name: 'linkTemplates',
      transform: {
        template_name: 'label',
        uuid: 'value',
      },
    },
    qr_templates: {
      name: 'qrTemplates',
      transform: {
        name: 'label',
        uuid: 'value',
        ...QR_BE_FE_MAP.getOne,
      },
    },
    settings: {
      name: 'settings',
      transform: {
        uuid: 'uuid',
        is_enabled: 'isEnabled',
        value: 'value',
        'name.value': 'name',
      },
    },
  },
  createLink: {
    short_url: 'shortURL',
    domain: 'domain',
    alias: 'alias',
  },
  validateSlug: {
    is_valid: 'isValid',
  },
  getRequiredAttributes: {
    name: 'name',
    redirect_type: 'redirectType',
    additional_parameter_schema: 'additionalParameterSchema',
    slug_prefix: 'slugPrefix',
    required_attributes: {
      name: 'requiredAttributes',
      transform: {
        description: 'description',
        code: 'code',
        data_type: 'dataType',
        default_value: 'defaultValue',
        field_type: 'fieldType',
        name: 'name',
        type: 'type',
        uuid: 'uuid',
        options: {
          name: 'options',
          transform: {
            code: 'code',
            name: 'name',
          },
        },
      },
    },
  },
};

export const FE_BE_MAP = {
  createTemplate: {
    name: 'name',
    description: 'description',
    slug: 'slug',
    redirectType: 'redirect_type',
    isTrustedDestinationEnabled: 'is_trusted_destination',
    isQRCodeEnabled: 'is_qr_code',
    domains: 'domains',
    linkTemplates: 'link_templates',
    qrTemplates: 'qr_templates',
    isShortUrlEditable: 'is_short_url_editable',
    isProtected: 'is_protected',
    hiddenFields: 'hidden_columns',
    isUseExistingLink: 'is_use_existing_link',
    settings: {
      name: 'settings',
      transform: {
        name: 'name',
        value: 'value',
        isEnabled: 'is_enabled',
      },
    },
  },
  createLink: {
    domain: 'domain_uuid',
    template: 'template_uuid',
    longUrl: 'long_url',
    slug: 'alias',
    description: 'description',
    additionalParameterSchema: 'additional_parameter_schema',
    attributes: 'assigned_attributes',
    redirectType: 'redirect_type',
  },
  getSlug: {
    domain: 'domain_uuid',
    template: 'template_uuid',
  },
  validateSlug: {
    slug: 'slug',
    domain: 'domain_uuid',
  },
  convertTypes: {
    targetFormat: 'target_format',
    sourceFormat: 'source_format',
    sourceData: 'source_file',
  },
};

export const Settings = {
  1: 'Background Color',
  2: 'Background Image',
  3: 'Logo',
  4: 'Header',
  5: 'Inputs Label Color',
  6: 'Button Color',
  7: 'Button Text Color',
  8: 'Headline',
  9: 'Headline Color',
  10: 'Description',
  11: 'Description Color',
  12: 'Footer',
  13: 'Text Color',
  14: 'Email',
  15: 'Phone Number',
  16: 'Custom CSS',
  17: 'Header color',
  18: 'Phone country code',
  19: 'Blink footer',
  20: 'Custom footer',
  21: 'Saved link header',
};
